import React, { Component } from 'react';
import Context from './Utils/context';
import Layer from './Components/Layer';
import AppearLayer from './Components/AppearLayer';

export {
  Layer,
  AppearLayer
}

export default class Parallax extends Component {
  constructor(props) {
    super(props);

    this.layers = [];
    this.appearLayers = [];
    this.offset = props.offset || 100;
  }

  addLayer = (layer) => {
    this.layers.push(layer);
  }

  removeLayer = (layer) => {
    this.layers = this.layers.filter(ly => ly !== layer);
  }

  addAppearLayer = (layer) => {
    this.appearLayers.push(layer);
  }

  removeAppearLayer = (layer) => {
    this.appearLayers = this.appearLayers(ly => ly !== layer);
  }

  getContext = () => {
    return {
      addLayer: this.addLayer,
      removeLayer: this.removeLayer,
      addAppearLayer: this.addAppearLayer,
      removeAppearLayer: this.removeAppearLayer
    }
  }

  componentDidMount() {
    this.setup();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  setup() {
    window.addEventListener('scroll', this.handleScroll);
    window.dispatchEvent(new Event('scroll'));
  }

  handleScroll = () => {
    window.requestAnimationFrame(this.check)
  }

  check = () => {
    this.checkParallaxItems();
    this.checkAppearItems();
  }

  isOnScreen = (layer) => {
    const position = layer.ref.current.getBoundingClientRect();
    return position.top < window.innerHeight && position.bottom >= 0;
  }

  checkAppearItems = () => {
    this.appearLayers.forEach(layer => {
      if (this.isOnScreen(layer)) {
        this.appear(layer);
      } else {
        //handle disappear
      }
    });
  }

  checkParallaxItems = () => {
    this.layers.forEach(layer => {
      if (this.isOnScreen(layer)) {
        this.draw(layer);
      }
    });
  }

  appear = (layer) => {
    layer.ref.current.style['animation-duration'] = layer.settings.duration;
    if (layer.settings.loop) {
      layer.ref.current.style['animation-iteration-count'] = layer.settings.loop;
    }

    layer.ref.current.classList.add(layer.settings.in);
  }

  draw = (layer) => {
    const item = layer.ref.current;
    const position = item.getBoundingClientRect();
    const settings = layer.settings || { type: 'backgroundY', speed: 0.3 };

    const speed = settings.speed;
    let newValue = -Math.floor(speed * position.top);

    const min = settings.min || (speed < 0 ? -this.offset : newValue);
    const max = settings.max || (speed > 0 ? this.offset : newValue);

    if (newValue < min) {
      newValue = min;
    } else if (newValue > max) {
      newValue = max;
    }

    const updatedValues = {};
    if (Array.isArray(settings.type)) {
      settings.type.forEach(t => {
        const { key, value } = this.getChangedValue(t, newValue);
        if (!updatedValues[key]) {
          updatedValues[key] = [];
        }
        updatedValues[key].push(value);
      })
    } else {
      const { key, value } = this.getChangedValue(settings.type, newValue);
      updatedValues[key] = [value];
    }

    // eslint-disable-next-line
    for (let key in updatedValues) {
      item.style[key] = updatedValues[key].join(' ');
    }
  }

  getChangedValue = (type, value) => {
    switch (type) {
      case 'translateY':
        return {
          key: 'transform',
          value: `translateY(${value}px)`
        };

      case 'translateX':
        return {
          key: 'transform',
          value: `translateX(${value}px)`
        }

      // case 'scale':
      //   const scale = value >= 0 ? Math.abs(value / 100) : Math.abs(100 / value);
      //   return {
      //     key: 'transform',
      //     value: `scale(${scale})`
      //   }

      case 'rotate':
        return {
          key: 'transform',
          value: `rotate(${value}deg)`
        }

      case 'backgroundPositionX':
        return {
          key: 'backgroundPosition',
          value: `${value}px center`
        };

      default:
        return {
          key: 'backgroundPosition',
          value: `center ${value}px`
        };
    }
  }

  render() {
    return (
      <Context.Provider value={this.getContext()}>
        {this.props.children}
      </Context.Provider>
    )
  }
}