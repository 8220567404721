import Context from '../Utils/context';
import Layer from './Layer';

export default class AppearLayer extends Layer {
  constructor(props) {
    super(props);

    ['in', 'duration', 'delay', 'loop'].forEach(setting => {
      this.settings = {
        ...this.settings,
        [setting]: props[setting]
      }
    });
  }

  componentDidMount() {
    this.context.addAppearLayer(this);
  }

  componentWillUnmount() {
    this.context.removeAppearLayer(this);
  }
}

AppearLayer.contextType = Context;