import React, { Component } from 'react';
import Context from '../Utils/context';

export default class Layer extends Component {
  constructor(props) {
    super(props);
    this.settings = props.settings;
    this.ref = React.createRef();
  }

  componentDidMount() {
    this.context.addLayer(this);
  }

  componentWillUnmount() {
    this.context.removeLayer(this);
  }

  render() {
    const props = { ...this.props, settings: null };
    return <div {...props} ref={this.ref}>
      {this.props.children}
    </div>
  }
}
Layer.contextType = Context;